import * as React from 'react'
import { useRouter } from 'next/router'

type HistoryContext = {
	history: string[]
	setHistory(data: string[]): void
	previousPath: string
	setPreviousPath(data: string): void
}

const HistoryContext = React.createContext<HistoryContext>({
	history: [],
	setHistory: () => null,
	previousPath: '',
	setPreviousPath: () => null,
})

export const HistoryProvider: React.FC = ({ children }) => {
	const { asPath } = useRouter()
	const [history, setHistory] = React.useState<string[]>([])
	const [previousPath, setPreviousPath] = React.useState<string>('')

	React.useEffect(() => {
		if (history.length) {
			setPreviousPath(history[history.length - 1])
		}
		setHistory((history) => [...history, asPath])
	}, [asPath])

	return (
		<HistoryContext.Provider
			value={{
				history,
				setHistory,
				previousPath,
				setPreviousPath,
			}}
		>
			{children}
		</HistoryContext.Provider>
	)
}

export const useHistory = () => React.useContext(HistoryContext)
