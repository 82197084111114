import * as React from 'react'
import Link from 'next/link'
import Search from 'components/Header/Search/container'
import BurgerMenu from './burger'
import { useSpring, animated } from 'react-spring'
import * as Icon from 'components/Icon'
import * as SVG from 'components/SVG'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useWindowSize } from 'utils/useWindowSize'
import { breakpointValues, breakpoints } from 'styles/breakpoints'
import GA from 'utils/analytics'
import { Container } from 'styles'

type Props = {
	showSearch: boolean
	promptLogin: () => void
	isAuthenticated: boolean
	goBack: () => void
	showGoBack: boolean
}

// Use the same width for login button and the my profile button to avoid layoutshifts
const loginAndMyProfileButtonWidth = 78

const Navigation = styled.nav`
	background: ${(props) => props.theme.HighlightPrimary};
	padding: 0 18px;
	height: ${(props) => props.theme.mainNavHeight};
	position: relative;
`

const TopLine = styled.div`
	background: ${(props) => props.theme.HighlightPrimary};
	position: fixed;
	inset: 0 0 auto 0;
	height: 12px;
	z-index: ${(props) => props.theme.zIndex.MENU_TOPLINE};

	@media ${breakpoints.tablet} {
		display: none;
	}
`

const NavigationContent = styled(Container)`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: ${(props) => props.theme.zIndex.MAIN_NAVIGATION};
	position: relative;
`

const LogoWrapper = styled.a`
	cursor: pointer;
	display: block;
	margin-top: 9px;
`

const HungryLogo = styled(SVG.HungryLogo)`
	display: blocK;
`

const BackIcon = styled(Icon.ChevronLeft)`
	color: ${(props) => props.theme.colors.white};
	width: 50px;
	box-sizing: border-box;
	position: relative;
	left: -12px;
	padding: 12px;
	@media ${breakpoints.tablet} {
		display: none !important;
	}
`

const SearchWrapper = styled(animated.div)`
	max-width: 450px;
	min-width: 300px;
	flex: 1;
	padding: 0 40px;
	margin: auto;
	display: none;

	@media ${breakpoints.tablet} {
		display: flex;
	}
`

const LinkList = styled.ul`
	padding: 0;
	list-style-type: none;
	align-items: center;
	display: none;

	@media ${breakpoints.tablet} {
		display: flex;
	}
`

const LinkListEntry = styled.li`
	margin: 0 12px;

	a {
		color: ${(props) => props.theme.colors.grayLight};
		white-space: pre;
		font-size: 16px;
	}
`

const MyProfileLink = styled(LinkListEntry)`
	width: ${loginAndMyProfileButtonWidth}px;
`

const LinkListEntryMarked = styled.button`
	border: 1px solid ${(props) => props.theme.colors.grayLight};
	border-radius: 4px;
	padding: 6px 12px;
	color: ${(props) => props.theme.colors.grayLight};
	font-size: 16px;
	cursor: pointer;
	background: transparent;
	width: ${loginAndMyProfileButtonWidth}px;
	white-space: pre;

	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`

const StyledBurgerMenu = styled(BurgerMenu)`
	margin-top: 6px;
	@media ${breakpoints.tablet} {
		display: none;
	}
`

const BurgerMenuContent = styled.ul`
	position: absolute;
	top: 56px;
	right: 0;
	list-style-type: none;
	margin: 0;
	display: flex;
	flex-direction: column;
	z-index: ${(props) => props.theme.zIndex.NAVIGATION_DROPDOWN_CONTENT};
	font-size: 16px;
	padding: 0;
	border-radius: 0px 0px 3px 3px;
`

const BurgerMenuLink = styled.li`
	padding: 16px 32px 16px;
	color: ${(props) => props.theme.colors.grayMediumDark};
	text-align: right;
	background: ${(props) => props.theme.colors.grayMediumLight};
	cursor: pointer;
	a {
		color: ${(props) => props.theme.colors.grayMediumDark};
	}
	&:hover {
		background: ${(props) => props.theme.colors.gray};
	}
`

const MainNavigationView = (props: Props) => {
	const { t } = useTranslation()
	const [isNavOpen, setIsNavOpen] = React.useState(false)
	const { width } = useWindowSize()
	const p = useSpring({
		opacity: props.showSearch ? 1 : 0,
		top: props.showSearch ? 0 : 10,
	})

	const toggleMenu = () => setIsNavOpen((prev) => !prev)

	const closeMenu = () => setIsNavOpen(false)

	return (
		<Navigation>
			<TopLine />
			<NavigationContent>
				{props.showGoBack && <BackIcon onClick={props.goBack} />}

				<Link href="/" shallow>
					<LogoWrapper id="logo">
						<HungryLogo width="80" fill="#FFF" />
					</LogoWrapper>
				</Link>
				<SearchWrapper style={p}>
					{props.showSearch ? (
						<Search
							onSuggestionModalShow={GA.sendGeoSearchShowSuggestionsModalNavbar}
							onSuggestionModalSelect={
								GA.sendGeoSearchSuggestionModalSelectNavbar
							}
						/>
					) : null}
				</SearchWrapper>

				<LinkList>
					<LinkListEntry>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://riders.hungry.dk/?utm_source=hungry.dk&utm_medium=organisk&utm_campaign=rider-recruit&utm_content=hungry.dk/"
						>
							{t('become_rider')}
						</a>
					</LinkListEntry>
					{!props.showSearch && (
						<LinkListEntry>
							<Link href="/[...slug]" as="/ny-restaurant/">
								<a>{t('become_partner')}</a>
							</Link>
						</LinkListEntry>
					)}
					<LinkListEntry>
						<Link href="/[...slug]" as="/kontakt/">
							<a>{t('contact')}</a>
						</Link>
					</LinkListEntry>
					{props.isAuthenticated ? (
						<MyProfileLink data-testid="my-profile-link">
							<Link href="/min-profil/">{t('navigation_my_profile')}</Link>
						</MyProfileLink>
					) : (
						<LinkListEntry>
							<LinkListEntryMarked
								onClick={props.promptLogin}
								data-testid="login-link"
							>
								{t('login_button')}
							</LinkListEntryMarked>
						</LinkListEntry>
					)}
				</LinkList>

				<StyledBurgerMenu isActive={isNavOpen} onClick={toggleMenu} />

				{isNavOpen && width && width < breakpointValues.tablet ? (
					<BurgerMenuContent>
						<BurgerMenuLink onClick={closeMenu}>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://riders.hungry.dk/?utm_source=hungry.dk&utm_medium=organisk&utm_campaign=rider-recruit&utm_content=hungry.dk/"
							>
								{t('become_rider')}
							</a>
						</BurgerMenuLink>
						<BurgerMenuLink onClick={closeMenu}>
							<Link href="/ny-restaurant">
								<a>{t('become_partner')}</a>
							</Link>
						</BurgerMenuLink>
						<BurgerMenuLink onClick={closeMenu}>
							<Link href="/kontakt">
								<a>{t('contact')}</a>
							</Link>
						</BurgerMenuLink>
						{props.isAuthenticated ? (
							<BurgerMenuLink onClick={closeMenu}>
								<Link href="/min-profil">{t('navigation_my_profile')}</Link>
							</BurgerMenuLink>
						) : (
							<BurgerMenuLink onClick={props.promptLogin}>
								{t('login_button')}
							</BurgerMenuLink>
						)}
					</BurgerMenuContent>
				) : null}
			</NavigationContent>
		</Navigation>
	)
}

export default MainNavigationView
