import React from 'react'
import ReactDOM from 'react-dom'
import { ModalContext } from './modalContext'
import styled from 'styled-components'
import ModalBaseLayout from 'components/Shared/Modals/Base'

const Modal = () => {
	const [isBrowser, setIsBrowser] = React.useState(false)
	const { modalContent, handleModal, modal } = React.useContext(ModalContext)

	const modalWrapperRef = React.useRef<HTMLDivElement>(null)

	// check if the user has clicked inside or outside the modal
	const backDropHandler = (e: Event) => {
		// @ts-ignore
		if (!modalWrapperRef?.current?.contains(e.target)) {
			//TODO: close modal on backdrop click
		}
	}

	React.useEffect(() => {
		setIsBrowser(true)

		// attach event listener to the window
		window.addEventListener('click', backDropHandler)

		return () => {
			window.removeEventListener('click', backDropHandler)
		}
	}, [])

	const handleCloseClick = () => {
		handleModal()
	}

	const wrappedModal = modal ? (
		<StyledModalOverlay>
			<StyledModalWrapper ref={modalWrapperRef}>
				<StyledModal>
					<ModalBaseLayout onClose={handleCloseClick}>
						{modalContent}
					</ModalBaseLayout>
				</StyledModal>
			</StyledModalWrapper>
		</StyledModalOverlay>
	) : null

	if (isBrowser && document.getElementById('modal-root')) {
		return ReactDOM.createPortal(
			wrappedModal,
			document.getElementById('modal-root')!
		)
	} else {
		return null
	}
}

const StyledModalWrapper = styled.div`
	//TODO: update nested z-index
	z-index: ${(props) => props.theme.zIndex.MODAL} !important;
`

const StyledModal = styled.div`
	background: ${(props) => props.theme.BackgroundLight};
	border-radius: 5px;
`

const StyledModalOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.7) !important;
	backdrop-filter: blur(2px) brightness(0.8);
`

export default Modal
