import * as React from 'react'
import NavigationView from './index-view'
import { useRouter } from 'next/router'
import { AuthContext } from 'providers/Authentication'

type Props = {
	children: React.ReactNode
}

const MainNavigation = (props: Props) => {
	const { promptLogin, isAuthenticated } = React.useContext(AuthContext)
	const router = useRouter()

	return (
		<>
			<NavigationView
				showSearch={router.pathname !== '/'}
				promptLogin={() => promptLogin()}
				isAuthenticated={isAuthenticated}
				goBack={router.back}
				showGoBack={router.pathname !== '/'}
			/>
			{props.children}
		</>
	)
}

export default MainNavigation
