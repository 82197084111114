import * as React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const Wrapper = styled(animated.span)`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	z-index: 1;
	width: 100%;
`

type Props = {
	children: React.ReactNode
	position: 'top' | 'bottom'
	duration: number
	onShowStyles: React.CSSProperties
	onHideStyles: React.CSSProperties
}

const AnimatedMarquee = (props: Props) => {
	const [showMarquee, setShowMarquee] = React.useState(false)
	const style = useSpring(showMarquee ? props.onShowStyles : props.onHideStyles)

	React.useEffect(() => setShowMarquee(true), [])

	React.useEffect(() => {
		const timeout = setTimeout(() => setShowMarquee(false), props.duration)
		return () => clearTimeout(timeout)
	})

	return <Wrapper style={style}>{props.children}</Wrapper>
}

export default AnimatedMarquee
