import React from 'react'
import ReactDOM from 'react-dom'
import MarqueeView from './view'
import { Subject } from 'rxjs'
import { CustomThemeProvider } from 'styles/ThemeProvider'
import MarqueeGlobalMessage from './GlobalMessage'
import { DefaultTheme } from 'styles/theme'
import AnimatedMarquee from './animated'
import { marqueeDivId } from 'components/Cart/Summary/MobileButton'
import { Marquee as CartMarquee } from 'components/Cart/Summary/MobileButton'
import { CartItemData } from 'components/Cart/CartItem'

type MarqueeConfig = {
	duration: number
	position: 'top' | 'bottom'
	onShowStyles: React.CSSProperties
	onHideStyles: React.CSSProperties
}

class Marquee {
	timeout?: NodeJS.Timeout
	/** This holds the text of the current marquee shown. If no marquee is shown it will be an empty string */
	current: Subject<string>

	constructor() {
		this.current = new Subject()
	}

	showGlobalMessage = (text: string) => {
		const root = document.getElementById('__next')

		if (!root) return
		const container = document.createElement('div')
		container.id = 'global-marquee'
		container.style.position = 'relative'
		container.style.zIndex = DefaultTheme.zIndex.GLOBAL_MARQUEE.toString()

		root.prepend(container)

		ReactDOM.render(
			<CustomThemeProvider>
				<MarqueeGlobalMessage text={text} />
			</CustomThemeProvider>,
			container
		)
	}

	/**
	 * @param id Element id where maruqee should be attached
	 * @param component The component that should be shown
	 */
	cart = (
		cartItem: CartItemData,
		config: MarqueeConfig = {
			duration: 2000,
			position: 'bottom',
			onShowStyles: { bottom: -5 },
			onHideStyles: { bottom: -40 },
		}
	) => {
		const root = document.getElementById(marqueeDivId)
		if (!root) return

		const element = document.createElement('div')
		element.style.width = '100%'

		root.appendChild(element)

		ReactDOM.render(
			<CustomThemeProvider>
				<AnimatedMarquee
					position={config.position}
					duration={config.duration}
					onShowStyles={config.onShowStyles}
					onHideStyles={config.onHideStyles}
				>
					<CartMarquee component="span" variant="body">
						{`+ ${cartItem.name}`}
					</CartMarquee>
				</AnimatedMarquee>
			</CustomThemeProvider>,
			element
		)

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		// The duration given is the duration that the marquee is shown.
		// After the duration the marquee is animated out.
		// Wait a little longer before removing it from the dom
		this.timeout = setTimeout(() => {
			ReactDOM.unmountComponentAtNode(element)
			root.childNodes.forEach((child) => {
				root.removeChild(child)
			})
			this.timeout = undefined
		}, config.duration + 500)
	}

	/** Displays marquee with a message for a given duration */
	show = (text: string, duration = 2000) => {
		const root = document.getElementById('__next')

		if (!root) return
		const container = document.createElement('span')
		container.id = 'marquee'

		root.appendChild(container)

		ReactDOM.render(
			<CustomThemeProvider>
				<MarqueeView duration={duration} position="bottom" text={text} />
			</CustomThemeProvider>,
			container
		)
		this.current.next(text)
		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		// The duration given is the duration that the marquee is shown.
		// After the duration the marquee is animated out.
		// Wait a little longer before removing it from the dom
		this.timeout = setTimeout(() => {
			ReactDOM.unmountComponentAtNode(container)
			root.removeChild(container)
			this.timeout = undefined
			this.current.next('')
		}, duration + 300)
	}
}

export default new Marquee()
