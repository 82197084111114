import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import I18n from './init'

type Props = {
  children: React.ReactNode
}

const TranslationProvider = (props: Props) => {
  return (
    <I18nextProvider i18n={I18n}>
      {props.children}
    </I18nextProvider>
  )
}

export default TranslationProvider