/** Appends a trailing slash if it doesn't end with a slash */
export const withTrailingSlash = (string: string) => {
	if (string.slice(-1) === '/') return string
	return string + '/'
}

/** Removes trailing slash if it ends with a slash */
export const withoutTrailingSlash = (string: string) => {
	if (string.slice(-1) === '/') return string.slice(0, string.length - 1)
	return string
}

export const withoutLeadingSlash = (string: string) => {
	if (string.startsWith('/')) return string.slice(1)
	return string
}
