import * as React from 'react'
import * as SVG from 'components/SVG'
import styled from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > svg {
		padding: 8px;
	}
`

const PaymentOptions = () => {
	return (
		<Wrapper>
			<SVG.DankortSmall />
			<SVG.VisaSmall />
			<SVG.VisaElectronSmall />
			<SVG.MastercardSmall />
			<SVG.ViabillSmallCart />
			<SVG.MobilepaySmall />
			<SVG.CashSmall />
		</Wrapper>
	)
}

export default PaymentOptions
