import React from 'react'
import useModal from './useModal'
import Modal from './modal'

type ModalContext = {
	modalContent: JSX.Element | null
	handleModal(content?: JSX.Element | null): void
	modal: boolean
}

const ModalContext = React.createContext<ModalContext>({
	modalContent: null,
	handleModal: () => null,
	modal: false,
})

const ModalProvider: React.FC = ({ children }) => {
	const { modal, handleModal, modalContent } = useModal()

	return (
		<ModalContext.Provider value={{ modal, handleModal, modalContent }}>
			<Modal />
			{children}
		</ModalContext.Provider>
	)
}

export { ModalContext, ModalProvider }
