import * as React from 'react'
import PaymentOptions from 'components/Cart/PaymentOptions'
import styled from 'styled-components'
import CockpitComponent from 'components/Cockpit/Component'
import { Component } from 'api/cockpit/types'
import { BaseStyles } from 'components/Cockpit/StyledContainer'
import { Container } from 'styles'
import * as SVG from 'components/SVG'
import { DefaultTheme } from 'styles/theme'
import footer from './content.json'
import { useRouter } from 'next/router'

const Logo = styled(SVG.HungryLogo)`
	margin: 24px;
`

const StyledFooter = styled.footer<{
	isConfirmationPage: boolean
}>`
	background: ${(props) => props.theme.colors.grayMediumLight};
	padding: 24px;
	margin-top: auto;

	ul {
		list-style-type: none;
	}

	a {
		color: ${(props) => props.theme.colors.grayAlmostDark};
		text-decoration: underline;
		&:hover {
			color: ${(props) => props.theme.colors.grayDark};
		}
	}

	${(props) =>
		props.isConfirmationPage &&
		`.hide-on-order-confirmation{
		display: none;
	}`}
`

const StyledVersion = styled.div`
	background: ${(props) => props.theme.colors.grayMediumLight};
	color: ${(props) => props.theme.colors.grayMediumLight};
	text-align: center;
`

const StaticContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

const Footer = () => {
	const router = useRouter()
	const version = (
		<StyledVersion>[{process.env.NEXT_PUBLIC_WEBSITE_VERSION}]</StyledVersion>
	)
	if (
		router.asPath.includes('/checkout/') ||
		router.asPath.includes('/order_confirmation/')
	)
		return version

	return (
		<StyledFooter
			isConfirmationPage={router.asPath.includes('/order_confirmation')}
		>
			<Container>
				<BaseStyles>
					<StaticContent>
						<Logo width={120} fill={DefaultTheme.colors.grayMediumDark} />
						<PaymentOptions />
					</StaticContent>

					<div>
						{footer.grid.map((item, index) => (
							<CockpitComponent
								key={index}
								component={(item as unknown) as Component}
							/>
						))}
					</div>
				</BaseStyles>
			</Container>
			{version}
		</StyledFooter>
	)
}

export default Footer
