import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Shared/Typography'
import BaseButtonLayout from 'components/Shared/Buttons/BaseLayout'
import AnimatedMarquee from 'components/Marquee/animated'

type Props = {
	onClick: () => void
	subtotal: number
	items: number
	children: string
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>

const Wrapper = styled.div`
	background: ${(props) => props.theme.InteractionBasket};
	border-radius: ${(props) => props.theme.BorderRadiusButton};
	position: relative;
	width: 100%;
`

export const Marquee = styled(Typography)`
	background: ${(props) => props.theme.InteractionBasket};
	color: ${(props) => props.theme.BackgroundLight} !important;
	width: 100%;
	display: block;
	padding: 10px 12px;
	border-radius: ${(props) => props.theme.BorderRadiusButton};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
`

const StyledButton = styled(BaseButtonLayout)`
	background: ${(props) => props.theme.InteractionSecondary};
	color: ${(props) => props.theme.BackgroundLight} !important;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	position: relative;
	z-index: 1;
`

const ButtonContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
`

const ItemCount = styled.div`
	flex: 1;
	justify-content: start;
	text-align: left;
`

const ButtonText = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	padding: 0 8px;
`

const Box = styled(Typography)<{ $disabled: boolean }>`
	background: ${(props) =>
		props.$disabled
			? props.theme.InteractionTertiery
			: props.theme.InteractionBasket};
	display: inline-block;
	padding: 5px;
	border-radius: ${(props) => props.theme.BorderRadiusButton};
	min-width: 24px;
	min-height: 24px;
	text-align: center;
	color: ${(props) => props.theme.BackgroundLight} !important;
`

const Subtotal = styled(Typography)`
	flex: 1;
	color: ${(props) => props.theme.BackgroundLight} !important;
	text-align: right;
`

const MarqueePlaceholder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
`

export const marqueeDivId = 'cart-marquee'

const CartMobileButton = ({ subtotal, items, children, ...props }: Props) => {
	return (
		<Wrapper>
			<MarqueePlaceholder id={marqueeDivId}>
				<div style={{ display: 'none' }}>
					{/* Not having this in the DOM causes the cart button to flicker once the styles are loaded in */}
					<AnimatedMarquee
						position="bottom"
						duration={0}
						onShowStyles={{ bottom: -5 }}
						onHideStyles={{ bottom: -40 }}
					>
						<Marquee component="span" variant="body"></Marquee>
					</AnimatedMarquee>
				</div>
			</MarqueePlaceholder>

			<StyledButton {...props}>
				<ButtonContent>
					<ItemCount>
						<Box $disabled={!!props.disabled} component="span" variant="body">
							{items}
						</Box>
					</ItemCount>
					<ButtonText>{children}</ButtonText>
					<Subtotal
						component="span"
						variant="body"
					>{`${subtotal} kr.`}</Subtotal>
				</ButtonContent>
			</StyledButton>
		</Wrapper>
	)
}

export default CartMobileButton
