import * as React from 'react'
import type {
	AppProps,
	// NextWebVitalsMetric,
} from 'next/app'
import HungryThemeProvider from 'styles/ThemeProvider'
import TranslationProvider from 'providers/Translation'
import AuthenticationProvider from 'providers/Authentication'
import Navigation from 'components/Navigation'
import Footer from 'components/Footer'
import { useRouter } from 'next/router'
import Head from 'next/head'
import helper from 'utils/helper'
import { withTrailingSlash } from 'utils/trailingSlash'
import api from 'api'
import { Redirect } from 'api/types'
import logger from 'utils/logger'
import useHungryStatusCheck from 'utils/useHungryStatusCheck'
import AddressProvider from 'providers/Address'
import { HistoryProvider } from 'providers/History'
import { ModalProvider } from 'components/Shared/Modals/contextual/modalContext'

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log(metric.name, metric)
// }

// There are issues with styled-components and React StrictMode. The styles will not be applied correctly when wrapped in StrictMode.
// To avoid these issues we wrap everything inside of our ThemeProvider in StrictMode.

export const defaultPageTitle =
	'Hungry.dk • Bestil Take Away Mad • Pizza, Burger, Sushi og mere'

function HungryApp({ Component, pageProps }: AppProps) {
	useHungryStatusCheck()
	const router = useRouter()
	const [redirects, setRedirects] = React.useState<Redirect[]>([])
	const defaultPageDescription =
		'Få lækker mad leveret hurtigt til døren fra mere end 1800 lokale restauranter i Danmark. Du får de bedste tilbud fra dine lokale favoritter.'
	let observer: MutationObserver | null = null

	const removeObserver = () => {
		if (observer) {
			observer.disconnect()
			observer = null
		}
	}

	const toggleFreshChat = (path: string, chat: Element) => {
		if (path.match('kontakt')) {
			chat.classList.add('show_fc')
		} else {
			chat.classList.remove('show_fc')
		}
	}

	React.useEffect(() => {
		api
			.getRedirects()
			.then((res) => setRedirects(res))
			.catch((err) => logger.newrelicError(err))
	}, [])

	React.useEffect(() => {
		const onRouteChangeComplete = () => {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
		}

		router.events.on('routeChangeComplete', onRouteChangeComplete)
		return () => router.events.off('routeChangeComplete', onRouteChangeComplete)
	}, [router])

	React.useEffect(() => {
		// Loop redirects to see if current path matches any of the redirect paths
		// We remove '/' and compare the routes, since /example and /example/ should both redirect to /redirect/
		const path = router.asPath.split('/').join('')
		const redirectTo = redirects.find((r) => {
			const url301 = r.url301.split('/').join('')
			return url301 === path
		})
		if (redirectTo) {
			router.replace(withTrailingSlash(redirectTo.url))
		}

		const chat = document.getElementById('fc_frame')
		if (chat) {
			toggleFreshChat(path, chat)
		} else {
			// wait until chat element rendered
			observer = new MutationObserver(() => {
				const chat = document.getElementById('fc_frame')
				if (chat) {
					removeObserver()
					toggleFreshChat(path, chat)
				}
			})
			observer.observe(document, { subtree: true, childList: true })
		}

		return () => removeObserver()
	}, [redirects, router.asPath])

	return (
		<HungryThemeProvider>
			<React.StrictMode>
				<HistoryProvider>
					<TranslationProvider>
						<AuthenticationProvider>
							<AddressProvider>
								<ModalProvider>
									<Navigation>
										<Head>
											{/* Disable zoom/prevent iOS devices from zooming on input focus */}
											<meta
												name="viewport"
												content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
											/>
											<title key="pageTitle">{defaultPageTitle}</title>
											<meta name="robots" content="index" />
											{router.asPath !== '/404' ? (
												<link
													rel="canonical"
													href={withTrailingSlash(helper.url + router.asPath)}
												/>
											) : null}

											<meta
												key="fb:app_id"
												property="fb:app_id"
												content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
											/>
											<meta
												name="description"
												content={defaultPageDescription}
											/>
											<meta
												key="og:description"
												property="og:description"
												content={defaultPageDescription}
											/>
											<meta
												key="og:type"
												property="og:type"
												content="website"
											/>
											<meta
												key="og:site_name"
												property="og:site_name"
												content={helper.companyNameShort}
											/>
											<meta
												key="fb:admins"
												property="fb:admins"
												content="817339828"
											/>
											<meta
												name="apple-itunes-app"
												content="app-id=666759664"
											/>
											<meta
												name="google-play-app"
												content="app-id=hungry.dk.android"
											/>

											<link rel="image_src" href={helper.ogImageSrc} />
											<link rel="author" href="humans.txt" />
											<meta
												key="og:image"
												property="og:image"
												content={helper.ogImageSrc}
											/>
											<meta
												key="og:image:type"
												property="og:image:type"
												content="image/png"
											/>
											<meta
												key="og:image:width"
												property="og:image:width"
												content="1200"
											/>
											<meta property="og:image:height" content="630" />

											<meta
												property="og:url"
												content={helper.url}
												key="og:url"
											/>
											<meta
												property="og:title"
												content={defaultPageTitle}
												key="og:title"
											/>
											<script
												type="application/ld+json"
												dangerouslySetInnerHTML={{
													__html: JSON.stringify(
														{
															'@context': 'https://schema.org',
															'@type': 'Organization',
															name: helper.companyName,
															url: helper.url,
															sameAs: ['https://www.facebook.com/hungrydotdk/'],
															logo: `${helper.url}/assets/images/hungry_logo_green.svg`,
														},
														null,
														2
													),
												}}
											/>
										</Head>
										<Component {...pageProps} />
										<Footer />
									</Navigation>
								</ModalProvider>
							</AddressProvider>
						</AuthenticationProvider>
					</TranslationProvider>
				</HistoryProvider>
			</React.StrictMode>
		</HungryThemeProvider>
	)
}

export default HungryApp
