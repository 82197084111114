import * as React from 'react'
import { useSpring, animated } from 'react-spring'
import Typography from 'components/Typography'
import styled from 'styled-components'

const Wrapper = styled(animated.span)`
	position: fixed;
	left: 0;
	right: 0;
	background: ${(props) => props.theme.colors.grayDark};
	color: ${(props) => props.theme.colors.white};
	padding: 12px;
	text-align: center;
	z-index: ${(props) => props.theme.zIndex.MARQUEE};
`

const Marquee = styled(Typography)`
	color: #fff;
`

type Props = {
	text: string
	position: 'top' | 'bottom'
	duration: number
}

const MarqueeView = (props: Props) => {
	const [showMarquee, setShowMarquee] = React.useState(false)
	const style = useSpring(
		showMarquee ? { [props.position]: 0 } : { [props.position]: -100 }
	)
	const text = useSpring({
		from: { transform: 'scale(1.1)' },
		to: { transform: 'scale(1)' },
		reset: true,
	} as React.CSSProperties)

	React.useEffect(() => setShowMarquee(true), [])

	React.useEffect(() => {
		if (props.text) {
			const timeout = setTimeout(() => setShowMarquee(false), props.duration)
			return () => clearTimeout(timeout)
		}
	})

	return (
		<Wrapper style={style}>
			<Marquee variant="subtitle1" component={animated.p} style={text}>
				{props.text}
			</Marquee>
		</Wrapper>
	)
}

export default MarqueeView
