import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Typography'

type Props = {
	text: string
}

const Wrapper = styled.div`
	background: #ffe7d2;
	padding: 12px;
`

const Text = styled(Typography)`
	text-align: center;
`

const MarqueeGlobalMessage = (props: Props) => {
	return (
		<Wrapper>
			<Text component="p" variant="h6">
				{props.text}
			</Text>
		</Wrapper>
	)
}

export default MarqueeGlobalMessage
