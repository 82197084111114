import * as React from 'react'
import Marquee from 'components/Marquee'
import Modal from 'components/Modal'
import TemporarilyOutOfServiceModal from 'components/Modal/TemporarilyOutOfService'
import { useRouter } from 'next/router'
import TemporarilyClosedModal from 'components/Modal/TemporaryClosed'
import api from 'api'
import logger from './logger'
import BaseError from 'components/Modal/base-error'

declare global {
	interface Window {
		hungryIsDown: boolean
		hungryGlobalMessage: string
	}
}

/**
 * Checks and reflects the status of the backend.\
 * Checks are done based on static variables defined in /variables/status.js
 */
function useHungryStatusCheck() {
	const router = useRouter()

	React.useEffect(() => {
		api
			.getIsHungryClosed()
			.then((res) => {
				if (res.site_closed) {
					Modal.unclosable({
						html: (
							<BaseError>
								<TemporarilyClosedModal message={res.message} />
							</BaseError>
						),
					})
				}
			})
			.catch((err) => logger.newrelicError(err))
	}, [])

	React.useEffect(() => {
		// These variables are set when we load in the /variables/status.js file.
		// In case our api/backend is down, we can modify that file to let the users know our site is not functional.
		if (window.hungryGlobalMessage) {
			Marquee.showGlobalMessage(window.hungryGlobalMessage)
		}
		if (window.hungryIsDown) {
			Modal.unclosable({
				html: (
					<BaseError>
						<TemporarilyOutOfServiceModal onConfirm={router.reload} />
					</BaseError>
				),
			})
		}
	}, [])
}

export default useHungryStatusCheck
