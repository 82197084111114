import * as React from 'react'
import styled from 'styled-components'
import { Container } from 'styles'
import { breakpoints } from 'styles/breakpoints'

type Props = {
	children: React.ReactNode
	className?: string
}

const ContainerWithStyles = styled(Container)`
	max-width: 960px;
	-webkit-box-shadow: 0px 0px 2px 2px ${(props) => props.theme.colors.grayLight};
	-moz-box-shadow: 0px 0px 2px 2px ${(props) => props.theme.colors.grayLight};
	box-shadow: 0px 0px 2px 2px ${(props) => props.theme.colors.grayLight};
	background: ${(props) => props.theme.colors.grayLight};

	@media ${breakpoints.tablet} {
		margin-top: 32px;
		margin-bottom: 32px;
	}
`

export const BasicStyles = styled.div`
	h1,
	h2,
	h3,
	p,
	li,
	a {
		word-break: break-word;
	}
	h1 {
		font-size: 34px;
		line-height: 1.167;
	}
	h2 {
		font-size: 24px;
		line-height: 1.334;
	}
	h3 {
		font-size: 20px;
		line-height: 1.3;
	}
	p,
	li {
		font-size: 16px;
		line-height: 1.5;
		color: ${(props) => props.theme.colors.grayDark};
	}
	img {
		max-width: 100%;
		height: auto;
	}
`

const StyledContainer = (props: Props) => {
	return (
		<ContainerWithStyles className={props.className ? props.className : ''}>
			<BaseStyles>{props.children}</BaseStyles>
		</ContainerWithStyles>
	)
}

export const BaseStyles = (props: Props) => {
	return <BasicStyles>{props.children}</BasicStyles>
}

export default StyledContainer
