import * as React from 'react'
import styled, { css } from 'styled-components'

type Props = {
	isActive: boolean
	onClick: () => void
	className?: string
}

const BurgerLine = css`
	width: 20px;
	height: 2px;
	background-color: #fff;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
`

const Button = styled.button<{ isActive: boolean }>`
	padding: 15px 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	top: 2px;
	transition: background-color 0s 0.13s linear;

	&:focus {
		outline: none;
	}

	&:hover {
		opacity: 0.7;
	}

	${(props) =>
		props.isActive &&
		`
		&:hover {
			opacity: 0.7;
		}

		& > span > span,
		& > span > span::before,
		& > span > span::after {
			background-color: #FFF;
		}
	`}
`

const HamburgerBox = styled.span`
	width: 20px;
	height: 12px;
	display: inline-block;
	position: relative;
`

const HamburgerInner = styled.span<{ isActive: boolean }>`
	display: block;
	top: 50%;
	margin-top: -2px;
	top: 2px;
	transition: background-color 0s 0.13s linear;
	${(props) =>
		props.isActive &&
		`
		transition-delay: 0.22s;
		background-color: transparent !important;
	`}

	${BurgerLine};
	&::before {
		${BurgerLine};
		content: '';
		display: block;
		top: -5px;
		top: 5px;
		transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
			transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

		${(props) =>
			props.isActive &&
			`
			top: -4px;
			transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
			transform: translate3d(0, 10px, 0) rotate(45deg);
		`}
	}
	&::after {
		${BurgerLine};
		content: '';
		display: block;
		bottom: -5px;
		top: 10px;
		transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
			transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		${(props) =>
			props.isActive &&
			`
			top: -4px;
			transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
			transform: translate3d(0, 10px, 0) rotate(-45deg);
		`}
	}
`

const BurgerMenu = (props: Props) => {
	return (
		<Button
			isActive={props.isActive}
			onClick={props.onClick}
			className={props.className ? props.className : ''}
			type="button"
			aria-label="Menu"
		>
			<HamburgerBox>
				<HamburgerInner isActive={props.isActive} />
			</HamburgerBox>
		</Button>
	)
}

export default BurgerMenu
