import React from 'react'

const useModal = () => {
	const [modal, setModal] = React.useState(false)
	const [modalContent, setModalContent] = React.useState(null)

	const handleModal = (content = null) => {
		setModal(!modal)
		if (content) {
			document.body.classList.add('modal-open')
			setModalContent(content)
		} else {
			document.body.classList.remove('modal-open')
		}
	}

	return { modal, handleModal, modalContent }
}

export default useModal
