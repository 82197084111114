import * as React from 'react'
import Typography from 'components/Typography'
import { useTranslation } from 'react-i18next'

type Props = {
	message: string
}

const TemporarilyClosedModal = (props: Props) => {
	const { t } = useTranslation()

	return (
		<div>
			<Typography component="h1" variant="h5">
				{t('temporarily_closed_modal_title')}
			</Typography>
			<Typography component="p" variant="body1">
				{props.message}
			</Typography>
		</div>
	)
}

export default TemporarilyClosedModal
