import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Typography'
import { DefaultButton } from 'components/Buttons'
import { useTranslation } from 'react-i18next'

const Text = styled(Typography)`
	margin-bottom: 12px;
`

type Props = {
	onConfirm: () => void
}

const TemporarilyOutOfServiceModal = (props: Props) => {
	const { t } = useTranslation()

	return (
		<div>
			<Text variant="body1" component="p">
				{t('temporarily_out_of_service_modal_text')}
			</Text>
			<DefaultButton onClick={props.onConfirm}>
				{t('temporarily_out_of_service_modal_button')}
			</DefaultButton>
		</div>
	)
}

export default TemporarilyOutOfServiceModal
